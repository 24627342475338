<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">
                  Gestion des formulaires
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-2" style="margin-bottom: 20px;">

        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <ng-container *ngIf="profile_id === 11">
          <div class="alert alert-danger" style="margin-left: 12px">La création de formulaire s'effectue seulement avec un compte administrateur de site</div>
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">LISTE DES FORMULAIRES ({{cpt_forms}}) <small style="color: green;font-weight: bold;" *ngIf="showCopyDone"> Lien copié !</small></h4>

                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                    <tr>
                      <th>Titre</th>
                      <th>Propriétaire</th>
                      <th>Groupe</th>
                      <th>Liens</th>
                      <th>Date de création</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let f of forms  | paginate: { itemsPerPage: 10, currentPage: page }; let i=index">
                      <td> {{f.label}} </td>
                      <td>{{f.name}} {{f.fisrtname}}</td>
                      <td>{{f.g_name}} </td>
                      <td><a target="_blank" href="{{urlFormRegistration+''+f.token}}">{{urlFormRegistration+''+f.token | shortUrl}}</a>  <i class="bx bx-copy-alt" style="cursor: pointer;" (click)="copyMessage(urlFormRegistration+''+f.token)">Copier</i></td>
                      <td> {{f.created_at | date:'dd/MM/yyyy à H:mm:ss'}} </td>
                      <td>
                        <ng-container *ngIf="entityType == 'IP'">
                          <ng-container *ngIf="f.site_id === 23">
                            Non disponible
                          </ng-container>
                          <ng-container *ngIf="f.site_id !== 23">
                            <a href="/dashboard/administrator/formulaire/editer/{{f.id}}"><i class="bx bx-edit"></i>Editer</a>
                            <a><i class="bx bx-trash-alt pointer" (click)="delete_form(f.id)"></i><span class="pointer" (click)="delete_form(f.id)">Supprimer</span></a>
                          </ng-container>
                        </ng-container>
                      
                        <ng-container *ngIf="entityType == 'EKAMPUS'">
                          <ng-container *ngIf="f.site_id === 23 || f.site_id === 58 || f.site_id === 48 || f.site_id === 3">
                            Non disponible
                          </ng-container>
                          <ng-container *ngIf="f.site_id !== 23 && f.site_id !== 58 && f.site_id !== 48 && f.site_id !== 3">
                            <a href="/dashboard/administrator/formulaire/editer/{{f.id}}"><i class="bx bx-edit"></i>Editer</a>
                            <a><i class="bx bx-trash-alt pointer" (click)="delete_form(f.id)"></i><span class="pointer" (click)="delete_form(f.id)">Supprimer</span></a>
                          </ng-container>
                        </ng-container>
                      </td>                      
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12 text-center">
                  <pagination-controls
                    previousLabel="Précedent"
                    nextLabel="Suivant"
                    responsive="true"
                    (pageChange)="page = $event"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="profile_id != 11">
          <ng-container *ngIf="cpt_forms === 0">
            <div class="col-lg-12">
              <div class="jumbotron">
                <h2 class="display-4 text-center">Bienvenue dans le ménu de création de formulaire </h2>
                <p class="lead text-center">Nous remarquons que vous n'avez pas encore de formulaire d'auto-inscription crée, vous pouvez ne créer en cliquant sur le bouton ci-dessous : </p>
                <hr class="my-4">

                <a href="/dashboard/administrator/formulaire/create">
                  <button type="button" class="btn btn-primary waves-effect waves-light text-center">
                    <i class="bx bx-plus font-size-16 align-middle mr-2"></i> Créer un formulaire
                  </button>
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="cpt_forms > 0">
            <div class="">
              <div class="col-lg-12" style="margin-bottom: 20px;">
                <a href="/dashboard/administrator/formulaire/create">
                  <button type="button" class="btn btn-primary waves-effect waves-light text-center">
                    <i class="bx bx-plus font-size-16 align-middle mr-2"></i> Créer un nouveau formulaire
                  </button>
                </a>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">LISTE DES FORMULAIRES ({{cpt_forms}})</h4>
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                      <tr>
                        <th>Titre</th>
                        <th>Groupe</th>
                        <th>Liens</th>
                        <th>Date de création</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let f of forms  | paginate: { itemsPerPage: 5, currentPage: page }; let i=index">
                        <td> {{f.label}} </td>
                        <td>{{f.g_name}} </td>
                        <td><a target="_blank" href="{{urlFormRegistration+''+f.token}}">{{urlFormRegistration+''+f.token | shortUrl}}</a>  <i class="bx bx-copy-alt" style="cursor: pointer;" (click)="copyMessage(urlFormRegistration+''+f.token)">Copier</i></td>
                        <td> {{f.created_at | date:'dd/MM/yyyy à H:mm:ss'}} </td>
                        <td>
                          <a href="/dashboard/administrator/formulaire/editer/{{f.id}}"><i class="bx bx-edit"></i>Editer</a>
                          <a><i class="bx bx-trash-alt pointer" (click)="delete_form(f.id)"></i><span class="pointer" (click)="delete_form(f.id)">Supprimer</span></a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-12 text-center">
                    <pagination-controls
                      previousLabel="Précedent"
                      nextLabel="Suivant"
                      responsive="true"
                      (pageChange)="page = $event"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>
      </div>
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  </div>
  <!-- End Page-content -->
  <app-footer></app-footer>
