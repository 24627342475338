<app-header></app-header>
<app-sidebar [sidebarStyle]="showCustomPopup ? { 'opacity': '10%' } : null"></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace 
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">
                  <app-fildariane></app-fildariane>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <!--Aministrateur-->
        <ng-container *ngIf="profile_id==11">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-md-3">
                <div class="card mini-stats-wid">
                  <a href="dashboard/administrator/clients">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Clients</p>
                        <h4 class="mb-0"><ng-container *ngIf="showClient"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_client}}</h4>
                      </div>
                      <div class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span class="avatar-title">
                              <i class="bx bx-copy-alt font-size-24"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mini-stats-wid">
                  <a href="dashboard/administrator/superviseurs">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Superviseurs</p>
                        <h4 class="mb-0"><ng-container *ngIf="showSuperviseur"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_superviseur}}</h4>
                      </div>

                      <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span class="avatar-title rounded-circle bg-primary">
                            <i class="bx bx-archive-in font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mini-stats-wid">
                  <a href="dashboard/administrator/verificateurs">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Vérificateurs</p>
                        <h4 class="mb-0"><ng-container *ngIf="showVerificateur"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_verificateur}}</h4>
                      </div>

                      <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span class="avatar-title rounded-circle bg-primary">
                              <i class="bx bx-purchase-tag-alt font-size-24"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mini-stats-wid">
                  <a href="/dashboard/administrator/intervenants">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Intervenants/Apprenants</p>
                        <h4 class="mb-0"><ng-container *ngIf="showIntervenant"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_intervenant}}</h4>
                      </div>

                      <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span class="avatar-title rounded-circle bg-primary">
                              <i class="bx bx-purchase-tag-alt font-size-24"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </ng-container>
        <!--Client-->
        <ng-container *ngIf="profile_id==12 || profile_id==1">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-md-4">
                <div class="card mini-stats-wid">
                  <a href="dashboard/administrator/superviseurs">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Superviseurs</p>
                        <h4 class="mb-0"><ng-container *ngIf="showSuperviseur"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_superviseur}}</h4>
                      </div>

                      <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span class="avatar-title rounded-circle bg-primary">
                              <i class="bx bx-purchase-tag-alt font-size-24"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
             <div class="col-md-4">
                <div class="card mini-stats-wid">
                  <a href="dashboard/administrator/verificateurs">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Vérificateurs</p>
                        <h4 class="mb-0"><ng-container *ngIf="showVerificateur"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_verificateur}}</h4>
                      </div>

                      <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span class="avatar-title rounded-circle bg-primary">
                              <i class="bx bx-purchase-tag-alt font-size-24"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card mini-stats-wid">
                  <a href="/dashboard/administrator/intervenants">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <p class="text-muted font-weight-medium">Intervenants/Apprenants</p>
                        <h4 class="mb-0"><ng-container *ngIf="showIntervenant"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_intervenant}}</h4>
                      </div>

                      <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span class="avatar-title rounded-circle bg-primary">
                              <i class="bx bx-purchase-tag-alt font-size-24"></i>
                          </span>
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
              </div>

            </div>
            <!-- end row -->
          </div>
        </ng-container>
        <!--Superviseur-->
        <ng-container *ngIf="profile_id==2 || profile_id==4 || profile_id==5 || profile_id==6 || profile_id==9 || profile_id==10">
          <div class="col-md-12">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted font-weight-medium">Vérificateurs</p>
                    <h4 class="mb-0"><ng-container *ngIf="showVerificateur"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_verificateur}}</h4>
                  </div>

                  <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span class="avatar-title rounded-circle bg-primary">
                          <i class="bx bx-purchase-tag-alt font-size-24"></i>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mini-stats-wid">
              <a href="/dashboard/administrator/intervenants">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted font-weight-medium">Intervenants/Apprenants</p>
                    <h4 class="mb-0"><ng-container *ngIf="showIntervenant"><img src="assets/img/loaderkapi.gif" height="18"></ng-container>{{c_intervenant}}</h4>
                  </div>

                  <div class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span class="avatar-title rounded-circle bg-primary">
                          <i class="bx bx-purchase-tag-alt font-size-24"></i>
                      </span>
                  </div>
                </div>
              </div>
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="profile_id==14 || profile_id==3">
          <div class="col-xl-12">
            <div class="row">
            </div>
            <!-- end row -->
          </div>
        </ng-container>
      </div>
      <!-- end row -->

      <ng-container *ngIf="profile_id==14 || profile_id==3">
        <div class="container-fluid" style="padding-top: 50px; margin-left: 0px" >
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="card">
                <img src="../../../assets/img/certikap_accueil_certification_00.png" class="card-img-top"/>
                <div class="card-body">
                  pour visualiser l'historique de vos formations et leurs dates de validité.
                </div>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-primary" style="margin-bottom: 10px; color: white" (click)="viewCertificats(connected_id)"><i class="bx bxs-log-in font-size-16 align-middle mr-2"></i>  Mes certificats</a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="card">
                <img src="../../../assets/img/certikap_accueil_formation_00.png" />
                <div class="card-body">
                  pour accéder directement à vos formations en ligne en cours ou à suivre. </div>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-primary" style="margin-bottom: 10px; color: white" (click)="checkIfChoiceScreen()"><i class="bx bxs-log-in font-size-16 align-middle mr-2"></i>  Mes formations</a>
                </div>
              </div>
            </div>
            <ng-container *ngIf="entityType == 'IP'">
            <div class="col-12 col-md-4">
              <div class="card">
                <img src="../../../assets/img/certikap_accueil_catalogue_00.png" />
                <div class="card-body">
                  pour vous inscrire directement à une session de votre choix. </div>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-primary" style="margin-bottom: 10px; color: white" (click)="viewCatalogueFr()"><i class="bx bxs-log-in font-size-16 align-middle mr-2"></i>  En Français</a>
                  <a class="btn btn-primary ml-2" style="margin-bottom: 10px; color: white" (click)="viewCatalogueEn()"><i class="bx bxs-log-in font-size-16 align-middle mr-2"></i>  In English</a>
                </div>
              </div>
            </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div *ngIf="showCustomPopup" class="modal-overlay"></div>
      <div *ngIf="showCustomPopup == true" style="display: block; position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center;" class="modal" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header bg-danger text-white">
              <h5 class="modal-title" id="ModalLabel">{{ChoiceScreen.description}}</h5>
              <button type="button" class="close" (click)="closeCustomPopup()" data-dismiss="modal" aria-label="Fermer">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="choice-container row-cols-8 row-cols-sm-12" [ngClass]="{'two-buttons-per-line': ChoiceScreen.groupes.length <= 2}">
                <div *ngFor="let choiceScreenGroups of ChoiceScreen.groupes" class="choice mt-2">
                  <button class="btn btn-primary choice-button row-cols-4 row-cols-sm-6 " style="width: 210px" (click)="inscriptionGroupChoice(choiceScreenGroups.g_remote_id)">
                    {{ choiceScreenGroups.custom_field }}
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button style="margin-right: 20px" type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeCustomPopup()">Retour</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- container-fluid -->
</div>
<!-- End Page-content -->
<app-footer></app-footer>
