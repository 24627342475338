<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Chargement de la liste en cours...</p>
</ngx-spinner>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white" *ngIf="showLoaderExport">Exportation des administrateurs en cours...</p>
</ngx-spinner>
<ngx-spinner *ngIf="showLoadConnection"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Processus en de prise en main en cours...</p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">Gestion des administrateurs de site
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-2" style="margin-bottom: 20px;">
          <a href="/dashboard/administrator/utilisateurs">
            <button type="button" class="btn btn-primary waves-effect waves-light">
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i> Ajouter
            </button>
          </a>
        </div>
        <div class="col-lg-2" style="margin-bottom: 20px;float: right">
          <button type="button" class="btn btn-primary waves-effect waves-light" (click)="exporter()">
            <i class="bx bx-export font-size-16 align-middle mr-2"></i> Exporter
          </button>
        </div>

        <div class="col-lg-12">
          <input class="form-control" type="text" id="example-text-input" placeholder="Veuillez entrer le nom ou prénom du client à rechercher" [(ngModel)]="vQuery">
        </div>
      </div>

    </div>
      <br>
      <div class="row">
        <div class="col-lg-9">
          <div class="alert alert-success text-center" role="alert" *ngIf="addingSuccess">
            Action effectuée avec <a href="#" class="alert-link">succès</a>.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div id="accordion">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">LISTE DES ADMINISTRATEURS DE SITE ({{count_client}})</h4>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead>
                  <tr>
                    <th [class.active]="order === 'user.name'"
                        (click)="setOrder('user.name')">Nom
                      <span [hidden]="reverse">▼</span
                      ><span [hidden]="!reverse">▲</span></th>
                    <th [class.active]="order === 'user.firstname'"
                        (click)="setOrder('user.firstname')">
                      Prénom <span [hidden]="reverse">▼</span
                    ><span [hidden]="!reverse">▲</span></th>
                    <ng-container *ngIf="entityType == 'EKAMPUS'">
                    <th>Sites </th>
                    </ng-container>
                    <th [class.active]="dateOrder === 'user.start_date'" (click)="setDateOrder('user.start_date')">
                      Date de début <span *ngIf="dateReverse">▲</span>
                      <span *ngIf="!dateReverse">▼</span>
                    </th>                    
                    <th [class.active]="dateOrder === 'user.end_date'" (click)="setDateOrder('user.end_date')">
                      Date de fin <span [hidden]="dateReverse">▼</span>
                      <span [hidden]="!dateReverse">▲</span>
                    </th>
                    <th>Statut</th>
                    <th>Actions</th>
                    <th></th>
                  </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let c of getFilteredUsers() | orderBy: order:reverse:false | paginate: { itemsPerPage: 10, currentPage: page } | slice:0:show; let i=index">
                      <td> {{c.user.name}} </td>
                      <td>{{c.user.firstname}}</td>
                      <ng-container *ngIf="entityType == 'EKAMPUS'">
                      <td>
                        <ng-container *ngIf="profile_id === 11">
                          <a class="btn btn-primary" data-toggle="collapse" href="#collapse_{{c.user.id}}" role="button" aria-expanded="false" data-target="collapse_{{c.user.id}}" (click)="listingSiteUser(c.user.id, c.user.profile_id)">
                            Voir les sites
                          </a>
                          <div class="collapse" id="collapse_{{c.user.id}}" data-parent="#accordion">
                            <ng-container *ngIf="displayBody">
                              <div class="card card-body">
                                <ul class="list-group" *ngFor="let user_site of user_sites">
                                  <span *ngIf="showLoader"><img src="../../../../assets/img/loaderkapi.gif" height="12"></span>
                                  <ng-container *ngIf="showLabel"> <li class="list-group-item">{{user_site.name}}</li></ng-container>
                                </ul>
                              </div>
                            </ng-container>

                          </div>
                        </ng-container>
                        <ng-container *ngIf="profile_id !== 11">
                          <a class="btn btn-primary" data-toggle="collapse" href="#multiCollapseExample{{c.user.id}}" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" style="height: 30%;font-size: 10px;">Voir les sites</a>
                          <div class="collapse multi-collapse" id="multiCollapseExample{{c.user.id}}">
                            <div class="card card-body">
                              <ul class="list-group" *ngFor="let cSite of c.sites">
                                <ng-container *ngIf="site_connected_id === cSite.id">
                                  <li class="list-group-item">{{cSite.name}} </li>
                                </ng-container>
                              </ul>
                            </div>
                          </div>
                        </ng-container>
                      </td>
                      </ng-container>

                      <td><span *ngIf="c.user.start_date !== '01/01/1970'">{{ convertToDate(c.user.start_date) | date:'dd/MM/yyyy' }}</span></td>
                    <td><span *ngIf="c.user.end_date !== '01/01/1970'">{{ convertToDate(c.user.end_date) | date:'dd/MM/yyyy' }}</span></td>
                      <td>
                        <span class="badge badge-success" *ngIf="c.user.active === 1">ACTIF</span>
                        <span class="badge badge-danger" *ngIf="c.user.active === 0">NON ACTIF</span>
                      </td>
                      <td>
                        <a href="/dashboard/administrator/utilisateurs/editer/{{c.user.id}}"><i class="bx bx-edit"></i>Editer</a>
                        <a  (click)="delete_client(c.user.id)"><i class="bx bx-trash-alt pointer"></i><span class="pointer">Supprimer</span></a>
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary waves-effect waves-light" style="height: 30%;font-size: 10px; cursor: pointer" (click)="prise_en_main(c.user.login, c.user.email)">
                          <i class="bx bxs-log-in font-size-16 align-middle mr-2"></i>  Prendre en main
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div class="col-md-12 text-center">
                  <pagination-controls
                    previousLabel="Précedent"
                    nextLabel="Suivant"
                    responsive="true"
                    (pageChange)="page = $event"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div class="col-lg-3" *ngIf="profile_id === 11">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">FILTRER</h4>
              <ng-container *ngIf="entityType == 'EKAMPUS'">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Site</label>
                <div class="col-md-8">
                  <select class="custom-select" [(ngModel)]="site_id" (change)="filterSite($event.target.value)">
                    <option disabled selected="">Veuillez sélectionner le site</option>
                    <option value="Tout">Tout</option>
                    <option *ngFor="let site of sites" value="{{site.id}}">{{site.name}}</option>
                  </select>
                </div>
              </div>
              </ng-container>
              <div class="form-group row">
                <div class="col-md-4">
                  <label class="row-md-4 col-form-label">Actif</label>
                </div>
                <div class="col-md-8">
                  <div class="form-check row-10 row-md-8">
                    <input class="form-check-input" type="radio" id="1" name="filtre" [checked]="isActifsChecked" (change)="filtrerUtilisateurs(1)">
                    <label class="form-check-label" for="1">Actifs</label>
                  </div>
                  <div class="form-check row-10 row-md-8">
                    <input class="form-check-input" type="radio" id="2" name="filtre" [checked]="isNonActifsChecked" (change)="filtrerUtilisateurs(0)">
                    <label class="form-check-label" for="2">Non Actifs</label>
                  </div>
                  <div class="form-check row-10 row-md-8">
                    <input class="form-check-input" type="radio" id="3" name="filtre"  [checked]="isTousChecked" (change)="afficherTousLesUtilisateurs()">
                    <label class="form-check-label" for="3">Tous</label>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
      <!-- end row -->
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  </div>
  <!-- End Page-content -->
  <app-footer></app-footer>
