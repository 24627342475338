import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfigService} from '../../../services/config.service';
import {VerificationService} from '../../../services/verification.service';
import {formatDate} from '@angular/common';
import * as moment from 'moment';
import Swal from "sweetalert2";
import {User} from "../../../models/user.model";
import {Verification} from "../../../models/verification.model";

@Component({
  selector: 'app-result-intervenant',
  templateUrl: './result-intervenant.component.html',
  styleUrls: ['./result-intervenant.component.css']
})
export class ResultIntervenantComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public admin_verifications: any;
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public profile_id = this.user_conntected.profile_id;
  public takeInHand = localStorage.getItem('takeInHand');
  public labelSousCertifcation: boolean;
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');

  constructor(
    public translate: TranslateService,
    public pageTitle: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private verificationService: VerificationService,
    private spinner: NgxSpinnerService,
    private config: ConfigService
  ) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Espace Vérificateur | Plateforme ALAYA');
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_detail(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
    }

  }
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  public site_id = parseInt(localStorage.getItem('site_id'));
  public verifications: any;
  public certificats: any;
  // tslint:disable-next-line:variable-name
  public photo_url ;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
  };

  // tslint:disable-next-line:variable-name
  verification_id: string;
  // tslint:disable-next-line:variable-name
  user_id: string;
  // tslint:disable-next-line:variable-name
  verificateur_id: string;

  // tslint:disable-next-line:variable-name
  intervenant_find = {
    name : null,
    firstname : null,
    photo : null,
    society : null,
    id : null,
  };
  certifications: any;
  user_details: User = new User(); // Initialisation par défaut avec un objet User vide
  verification: Verification = new Verification(); // Initialisation par défaut avec un objet User vide
  verification_statut: boolean;
  // tslint:disable-next-line:variable-name
  certificats_list_by_verification: any;
  // tslint:disable-next-line:variable-name
  single_verification: any;
  displaying: any;
  status: any;

  ngOnInit() {
    if (this.connectLikeVerifcateur === 'no') {
      this.router.navigate(['dashboard/administrator']);
      return false;
    }
    // this.config.redirectTools();
    this.get_list_admin_verification();
    this.user_id = this.activatedRoute.snapshot.url[4].path;
    this.verification_id = this.activatedRoute.snapshot.url[5].path;
    this.verificateur_id = this.activatedRoute.snapshot.url[6].path;
    this.get_user_result_verification(this.user_id, this.verification_id);
    this.get_verification(this.activatedRoute.snapshot.url[5].path);
    this.get_certification_list_verification(this.activatedRoute.snapshot.url[5].path);
    this.get_list_verification(this.site_id);
  }

  logout_user() {
    this.loginService.logout().subscribe(
      res => {
        if(res.status == 'success'){
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          localStorage.removeItem('profile_id');
          localStorage.removeItem('site_id');
          localStorage.removeItem('takeInHand');
          localStorage.removeItem('connect_like_verificateur');
          this.router.navigate(['/']);
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur s\'est produite lors de la déconnexion',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur s\'est produite lors de la déconnexion',
          footer: '<a href>Equipe Kaptitude</a>'
        });
      }
    );
  }
  // tslint:disable-next-line:variable-name
  get_single_user_connected_detail(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.name = res.data.name;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_verification(id_verification: any) {
    this.spinner.show();
    this.verificationService.getOneVerification(id_verification)
      .subscribe(res => {
        // @ts-ignore
        this.single_verification = res.data;
      }, (err) => {
        console.log(err);
      });
  }

  // tslint:disable-next-line:variable-name
  get_certification_list_verification(verification_id) {
    this.verificationService.getCertificationByVerification(verification_id)
      .subscribe(res => {
        // @ts-ignore
        this.certificats_list_by_verification = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  // tslint:disable-next-line:variable-name
  get_user_result_verification(user_id, verification_id) {
    this.spinner.show();
    console.log('envoi ok')

    this.userService.get_intervenant_result(user_id, verification_id)
      .subscribe(res => {
        console.log('ok recu')

        console.log(res)
        this.verification = res.verification;
        this.user_details = res.user;
        this.verification_statut = res.verification_statut;
        this.certificats = res.certificats;
        this.spinner.hide();
        }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
  }

  reinitialiser() {
    this.router.navigate(['dashboard/inspector']);
  }

  modifier() {
    this.router.navigate(['dashboard/inspector/intervenant/status/11105/3'])
      .then(() => {
        window.location.reload();
      });
  }
  // tslint:disable-next-line:variable-name
  get_list_verification(id_site) {
    this.verificationService.getListVerificationbySiteId(id_site)
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.verifications = res.data;
      }, (err) => {
        console.log(err);
      });
  }
  verificationChoice(event: any) {
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['dashboard/inspector/intervenant/status/' + this.intervenant_find.id + '/' + event.target.value + '/' + this.verificateur_id])
      .then(() => {
        window.location.reload();
      });
  }

  // tslint:disable-next-line:variable-name
  returner(login_t) {
    this.spinner.show();
    const body = {
      login: login_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHandOf', 'no');
          localStorage.setItem('connect_like_verificateur', 'no');
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10 || res.profile_id === 8 || res.profile_id === 13) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
  get_list_admin_verification() {
    this.spinner.show();
    this.verificationService.getListVerification()
      .subscribe(res => {
        // @ts-ignore
        this.count_verification = res.data.length;
        // @ts-ignore
        this.admin_verifications = res.data;
        this.spinner.hide();
      }, (err) => {
        console.log(err);
      });
  }
}
