import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

const token = JSON.parse(localStorage.getItem('tokenCurrentConnected'));
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
  }),
};

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error.error}`);
    }
    console.log(error);
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private static extractData(res: Response) {
    return res || { };
  }

  getSessionByUser(): Observable<any> {
    return this.http.get(this.config.urlDEV + '/sessions', httpOptions).pipe(
      map(SessionService.extractData),
      catchError(SessionService.handleError));
  }

  getSessionCatalogueByUser(user_id): Observable<any> {
    return this.http.get(this.config.urlDEV + '/sessions/catalogue/user/' + user_id, httpOptions).pipe(
      map(SessionService.extractData),
      catchError(SessionService.handleError));
  }

  inscriptionSession(data): Observable<any> {
    return this.http.post(this.config.urlDEV + '/sessions/chapitre/inscription', data, httpOptions).pipe(
      map(SessionService.extractData),
      catchError(SessionService.handleError));
  }

  synchronisation_session_cron(): Observable<any> {
    return this.http.get(this.config.urlDEV + '/IP/get_sessions', httpOptions).pipe(
      map(SessionService.extractData),
      catchError(SessionService.handleError));
  }


}
