<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white">Affichage du resultat en cours...</p>
</ngx-spinner>
<section id="front_wrap">
  <section id="front_page" class="profile_step">
    <section id="menu_front">
      <span class="badge badge-danger" *ngIf="takeInHand && takeInHand === 'yes'">
        <h6 style="color: #ffffff;font-size: 12px;">
        <span class="bx bx-info-circle"></span> Prise en main en cours <br>de ce compte
       </h6>
         <button type="button" class="btn btn-primary waves-effect waves-light" (click)="returner(admin_login)">
            Retour au compte précédent
          </button>
      </span>
      <a class="menu_item menu_search current" href="dashboard/inspector"><img src="assets/img/icon_search.png"> <p>Recherche</p></a>
      <a class="menu_item menu_profile" href="dashboard/inspector/profile"><img src="assets/img/icon_profile.png"> <p>Profil</p></a>
      <a href="" (click)="logout_user()" class="menu_item menu_logout"><img src="assets/img/icon_logout.png"> <p>Déconnexion</p></a>
    </section>
    <section id="big_front">
      <div id="name_connected">
        <p>{{single_user.name}}</p>
        <img src="{{single_user.photo}}">
      </div>
      <div id="content_new_form">
        <form>
          <p class="h1 search_result_title">Résultat de votre recherche pour la vérification<br>"{{verification.name}}"</p>
          <div class="row" >

            <div class="col-sm-12">
              <img src="{{this.photo_url + '' + user_details.photo}}" class="rounded-circle" height="30" style="height: 30px; width: 30px;margin-top: 10px;">
              <p class="h3"><b>{{user_details.firstname}} {{user_details.name}}</b></p>
              <p class="h6" style="margin-top: 10px">{{user_details.society ? user_details.society : 'Société non défini' }}</p>
              <p class="h1">
                <ng-container *ngIf="verification_statut == false">
                  <span class="badge badge-pill badge-danger" style="background-color: red; margin-top: 10px"><i class="bx bxs-x-square"></i> Refusé</span>
                </ng-container>
                <ng-container *ngIf="verification_statut == true">
                  <span class="badge badge-pill badge-success" style="background-color: #3aef8d; margin-top: 10px"><i class="bx bx-check-circle"></i>Accepté</span>
                </ng-container>
              </p>
              <div class="container-fluid mobile_container">
                <div class="row head_row text-left">
                  <div class="col-md-6 col-lg-6 tt_nom_certif only_pc">
                    <div class="row">
                      <div class="col-12">
                        <i class="bx bxs-bookmark"></i> Certification
                      </div>
                      <div class="col-12">
                        <i class="bx bxs-graduation ml-3" style="margin-left: 42px"></i> Titre du parcours
                      </div>
                      <div class="col-12">
                        <i class="bx bx-bookmark" style="margin-left: 32px"></i> Sous-certification
                      </div>
                      <div class="col-12">
                        <i class="bx bxs-graduation" style="margin-left: 46px"></i> Titre du parcours
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-lg-2 tt_date_certif">
                    <i class="bx bx-calendar"></i> Date d'obtention
                  </div>
                  <div class="col-md-2 col-lg-2 tt_date_end_certif">
                    <i class="bx bx-calendar"></i> Date de fin de validité
                  </div>
                  <div class="col-md-2 col-lg-2 tt_valid_certif">
                    <i class="bx bx-info-circle"></i> Validité
                  </div>
                </div>
                <div class="row margin_in_row">
                  <div class="col-lg-12">
                    <div class="alert alert-danger" role="alert" *ngIf="certificats && certificats?.length === 0">
                      <p style="text-align: center; margin:20px 0 20px 0;">Cet intervenant ne possède aucun certificat pour la(les) vérification(s) sélectionnée(s) !</p>
                    </div>
                  </div>
<!--                  <div class="col-lg-12">-->
<!--                    <div class="alert alert-danger" role="alert" *ngIf="sucessArrays && sucessArrays.length === 0">-->
<!--                      <p style="text-align: center; margin:20px 0 20px 0;"> Cet intervenant n'a pas suivi de cours pour valider les certifications ci-dessous</p>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
                <div class="row list_scroll">
                  <div class="row text-left certif_wrap" *ngFor="let certification of certificats; let i = index;" [ngClass]="{'no-border': i === 0}">
                    <div class="cert col-md-6 col-lg-6">
                      <div class="icon-wrapper">
                        <i class="bx bxs-bookmark"></i>
                      </div>
                      <div class="text-wrapper">
                        <span class="only_mobile">Certification : </span>
                        <span class="label_cert">{{certification.certificat_name}}</span>
                        <ng-container *ngIf="certification.parcours_result && certification.parcours_result?.length === 0 && certification.sub_certifications?.length === 0">
                          <span class="alert-danger ml-2">Non inscrit ou non commencé</span>
                        </ng-container>
                      </div>
                    </div>
<!--                    <div class="col-md-2 col-lg-2">-->
<!--                      <ng-container *ngIf="certification.certificat_statut == 'Valide'">-->
<!--                        <span class="col_valid valid">Valide</span>-->
<!--                      </ng-container>-->
<!--                      <ng-container *ngIf="certification.certificat_statut == 'Non Valide'">-->
<!--                        <span class="col_valid no_valid">Non valide</span>-->
<!--                      </ng-container>-->
<!--                      <ng-container *ngIf="certification.certificat_statut == 'Invalidé'">-->
<!--                        <span class="col_valid no_valid">Invalidé</span>-->
<!--                      </ng-container>-->
<!--                    </div>-->
                    <div class="col-md-2 col-lg-2"> </div>
                    <div class="col-md-2 col-lg-2"> </div>
                    <div class="col-md-2 col-lg-2"> </div>

                    <ng-container *ngFor="let parcours_result of certification.parcours_result; let k = index">
                      <ng-container *ngIf="parcours_result && parcours_result?.length != 0">

                        <div class="cert col-md-6 col-lg-6">
                          <!-- Icône placée dans une div séparée -->
                          <div class="ml-3 icon-wrapper">
                            <i class="bx bxs-graduation"></i>
                          </div>
                          <!-- Texte placé à côté de l'icône -->
                          <div class="text-wrapper">
                            <span class="only_mobile">Titre du parcours : </span>
                            <span class="titre_parcours">{{parcours_result.parcour_label}}</span>
                          </div>
                        </div>
                        <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <span class="only_mobile">Date de certification : </span>{{parcours_result.date_validation}}</span>
                        </div>
                        <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                  <ng-container *ngIf="parcours_result.lesson_status=='not validated'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                  <ng-container *ngIf="parcours_result.lesson_status!='not validated'"><span class="only_mobile">Date de fin de validité : </span>{{parcours_result.date_fin_validite}}</ng-container>
                                </span>
                        </div>
                        <ng-container *ngIf="parcours_result.status_label!='non valide (expiré)'">
                          <div class="col_valid valid col-md-2 col-lg-2">
                            <i class="bx bx-check-circle"></i>
                            <span class="">
                              {{parcours_result.status_label}}
                            </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="parcours_result.status_label==='non valide (expiré)'">
                          <div class="col_valid no_valid col-md-2 col-lg-2">
                            <i class="bx bxs-x-square"></i>
                            <span class="">
                              {{parcours_result.status_label}}
                            </span>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="certification.sub_certifications && certification.sub_certifications?.length != 0">
                      <ng-container *ngFor="let sub_certifications of certification.sub_certifications; let k = index">
                        <div class="cert col-md-6 col-lg-6">
                          <!-- Icône placée dans une div séparée -->
                          <div class="icon-wrapper">
                            <i class="bx bx-bookmark" style="margin-left: 29px"></i>
                          </div>
                          <!-- Texte placé à côté de l'icône -->
                          <div class="text-wrapper">
                            <span class="only_mobile">Sous Certification : </span>
                            <span>{{sub_certifications.sub_certificat_name}}</span>
                          </div>
                        </div>
<!--                        <div class="col-md-2 col-lg-2">-->
<!--                          <ng-container *ngIf="sub_certifications.sub_certificat_statut == 'Valide'">-->
<!--                            <span class="ml-1 col_valid valid_sous_cert">Valide</span>-->
<!--                          </ng-container>-->
<!--                          <ng-container *ngIf="sub_certifications.sub_certificat_statut == 'Non Valide'">-->
<!--                            <span class="ml-1 col_valid no_valid_sous_cert">Non valide</span>-->
<!--                          </ng-container>-->
<!--                          <ng-container *ngIf="sub_certifications.sub_certificat_statut == 'Invalidé'">-->
<!--                            <span class="ml-1 col_valid no_valid_sous_cert">Invalidé</span>-->
<!--                          </ng-container>-->
<!--                        </div>-->
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <ng-container *ngFor="let sub_certificat_result of sub_certifications.sub_certificat_result; let k = index">
                          <div class="cert col-md-6 col-lg-6">
                            <!-- Icône placée dans une div séparée -->
                            <div class="ml-3 icon-wrapper">
                              <i class="bx bxs-graduation" style="margin-left: 27px"></i>
                            </div>
                            <!-- Texte placé à côté de l'icône -->
                            <div class="text-wrapper">
                              <span class="only_mobile">Titre du parcours : </span>
                              <span class="titre_parcours">{{sub_certificat_result.parcour_label}}</span>
                            </div>
                          </div>
                          <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <span class="only_mobile">Date de certification : </span>{{sub_certificat_result.date_validation}}
                                </span>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <span class="col_date">
                            <ng-container *ngIf="sub_certificat_result.lesson_status=='not validated'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                              <ng-container *ngIf="sub_certificat_result.lesson_status!='not validated'"><span class="only_mobile">Date de fin de validité : </span>{{sub_certificat_result.date_fin_validite}}</ng-container>
                            </span>
                          </div>
                          <ng-container *ngIf="sub_certificat_result.status_label!='non valide (expiré)'">
                            <div class="col_valid valid col-md-2 col-lg-2">
                              <i class="bx bx-check-circle"></i>
                              <span class="">
                              {{sub_certificat_result.status_label}}
                            </span>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="sub_certificat_result.status_label==='non valide (expiré)'">
                            <div class="col_valid no_valid col-md-2 col-lg-2">
                              <i class="bx bxs-x-square"></i>
                              <span class="">
                              {{sub_certificat_result.status_label}}
                            </span>
                            </div>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                    </ng-container>
                  </div>
                </div>

              </div>

              <div class="wrap">
                <div class="inner_table">
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="button_result">
          <button style="width: 150px;" class="blue_btn reinit_btn"  type="buuton" color="accent" (click)="reinitialiser()"><i class="fa fa-recycle"></i> Réinitialiser</button>
          <button style="width: 150px;" class="blue_btn"  type="buuton" color="accent" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"><i class="fa fa-edit"></i> Modifier</button>
        </div>
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            <ng-container *ngIf="profile_id === 11">
              <select class="form-control verification"  [(ngModel)]="verification_id" [ngModelOptions]="{standalone: true}" (change)="verificationChoice($event)">
                <option>Veuillez sélectionner une vérification</option>
                <option *ngFor="let verification of admin_verifications" [value]="verification.id" selected>{{verification.name}}</option>
              </select>
            </ng-container>
            <ng-container *ngIf="profile_id === 8 || profile_id === 2 || profile_id ===4 || profile_id ===5 || profile_id === 6 || profile_id ===9 || profile_id === 1">
              <select class="form-control verification"  [(ngModel)]="verification_id" [ngModelOptions]="{standalone: true}" (change)="verificationChoice($event)">
                <option>Veuillez sélectionner une vérification</option>
                <option *ngFor="let verification of verifications" [value]="verification.id" selected>{{verification.name}}</option>
              </select>
            </ng-container>

          </div>
        </div>
      </div>
      <div id="bottom_logo">
        <img src="assets/img/logo-kaptitude.png">
      </div>
    </section>
  </section>
  <div id="front_legal">
    <p>CERTIKAP - par <strong>Kaptitude</strong> - 2020</p>
  </div>
</section>
