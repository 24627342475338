import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../services/login.service';
import {UserService} from '../../../services/user.service';
import {ConfigService} from '../../../services/config.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ExportationService} from "../../../services/exportation.service";
import {CertificationService} from "../../../services/certification.service";
import {VerificationService} from "../../../services/verification.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private loginService: LoginService,
              private userService: UserService,
              private config: ConfigService,
              private http: HttpClient) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Mon profil >> Espace Vérificateur | Plateforme ALAYA');
    this.photo_url = this.config.urlDEV_images;
    if (this.user_conntected !== null) {
      this.get_single_user_connected_details(this.user_conntected.user_id ? this.user_conntected.user_id : this.user_conntected.id);
    }

  }

  // tslint:disable-next-line:variable-name
  public photo_url ;
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
    email : null,
    password : null,
    password_confirm : null,
    firstname : null,
    login : null,
    id : null,
  };

  success_Message: boolean;
  // tslint:disable-next-line:variable-name
  not_success_Message: boolean;
  text: any;
  fileData: File = null;
  previewUrl: any = null;
  // tslint:disable-next-line:variable-name
  addingSuccess: boolean;
  public takeInHand = localStorage.getItem('takeInHand');
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
  ngOnInit() {
    if (this.connectLikeVerifcateur === 'no') {
      this.router.navigate(['dashboard/administrator']);
      return false;
    }
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  logout_user() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('tokenCurrentConnected');
    localStorage.removeItem('profile_id');
    localStorage.removeItem('site_id');
    localStorage.removeItem('takeInHand');
    localStorage.removeItem('connect_like_verificateur');
    this.loginService.logout().subscribe(
      res => {
        if(res.status == 'success'){
          localStorage.removeItem('currentUser');
          localStorage.removeItem('tokenCurrentConnected');
          localStorage.removeItem('profile_id');
          localStorage.removeItem('site_id');
          localStorage.removeItem('takeInHand');
          localStorage.removeItem('connect_like_verificateur');
          this.router.navigate(['/']);

          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Une erreur s\'est produite lors de la déconnexion',
            footer: '<a href>Equipe Kaptitude</a>'
          });
        }
      },
      err => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Une erreur s\'est produite lors de la déconnexion',
          footer: '<a href>Equipe Kaptitude</a>'
        });
      }
    );
  }
  // tslint:disable-next-line:variable-name
  get_single_user_connected_details(id_user: number) {
    this.userService.getUser(id_user)
      .subscribe(res => {
        this.single_user.name = res.data.name;
        this.single_user.email = res.data.email;
        this.single_user.firstname = res.data.firstname;
        this.single_user.login = res.data.login;
        //this.single_user.password = res.data.password;
        this.single_user.id = res.data.id;
        this.single_user.photo = this.photo_url + '' + res.data.photo;
      }, (err) => {
        console.log(err);
      });
  }
  
  preview() {
    // Show preview
    // tslint:disable-next-line:prefer-const
    let mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    // tslint:disable-next-line:prefer-const
    let reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    // tslint:disable-next-line:variable-name
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  showNotMessageSuccess(text){

    this.text = text;
    this.not_success_Message = true;

    setTimeout (() => {
      this.not_success_Message = false;
    }, 5000);

  }

  showMessageSuccess(text){

    this.text = text;
    this.success_Message = true;

    setTimeout (() => {
      this.success_Message = false;
    }, 5000);

  }

  update_profile() {
    const body = {
      name: this.single_user.name,
      email: this.single_user.email,
      password: this.single_user.password,
      password_confirm: this.single_user.password_confirm,
      firstname: this.single_user.firstname,
      login: this.single_user.login,
      id: this.single_user.id,
    };
    this.userService.updateUSer(body)
      .subscribe(res => {
        if (res.success === true) {
          this.text = res.message;
          this.showMessageSuccess(this.text);
        }
        if (res.success === false) {
          this.text = res.message;
          this.showNotMessageSuccess(this.text);
        }
      }, (err) => {
        console.log(err);
      });
  }
// tslint:disable-next-line:variable-name

  returner(login_t) {
    const body = {
      login: login_t
    };
    // @ts-ignore
    this.userService.take_in_hand(body)
      .subscribe(res => {
        this.loginService.logout_take_in_hand();
        // @ts-ignore
        if (res.status === 'success') {
          // @ts-ignore
          localStorage.setItem('currentUser', JSON.stringify(res.user));
          localStorage.setItem('takeInHandOf', 'no');
          localStorage.setItem('connect_like_verificateur', 'no');
          // @ts-ignore
          localStorage.setItem('tokenCurrentConnected', JSON.stringify(res.token));
          // @ts-ignore
          this.loginService.currentUserSubject.next(res.user);
          // @ts-ignore
          localStorage.setItem('profile_id', res.profile_id);
          // @ts-ignore
          localStorage.setItem('site_id', res.site_id);
          // @ts-ignore
          if (res.profile_id === 10 || res.profile_id === 8 || res.profile_id === 13) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;

        }

      }, (err) => {
        console.log(err);
      });
  }
}
