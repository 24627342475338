<footer class="foot footer">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-auto text-center">
        <span class="certikap">2021 © Kaptitude</span>
        <span class="divider">|</span>
        <span class="enjin">Création agence web Enjin</span>
      </div>
    </div>
  </div>
</footer>




